@font-face {
  font-family: "GreatVibes";
  src: url("./components/fonts/Great_Vibes/GreatVibes-Regular.ttf")
    format("truetype");
}

@import url("https://fonts.googleapis.com/css2?family=Gruppo&display=swap");

@import url("https://fonts.googleapis.com/css2?family=Libre+Caslon+Text&family=Playfair+Display:ital,wght@1,500&display=swap");

@import url("https://fonts.googleapis.com/css2?family=Lobster&display=swap");

.App {
  text-align: center;
  overflow-y: "auto";
}
* {
  /* font: italic 1.2em "Fira Sans", serif; */
  /* font: normal 1.2em "Fira Sans", serif; */
  font-size: large;
  font-family: normal 1.2em "Gruppo", sans-serif;
}
.headerComp {
  display: flex;
  justify-content: center;
  align-items: center;
}
.headerContent {
  display: flex;
  justify-content: flex-start;
}

/* .hero-container{
  background-image: url(../cas-bg.jpg);
  height: 80vh;
  width: 100%;
  background-position: center;
  background-repeat: no-repeat;

} */
.hero-container {
  height: 100% !important;
  min-width: 100% !important;
}
.forAddingImg {
  background-image: url(../cas-bg.jpg);
  height: 100vh;
  width: 100%;
  background-repeat: no-repeat;
  background-position: center;
  color: white;
  font-size: larger;
  font-family: Georgia, "Times New Roman", Times, serif;
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

/* Css for lyrics page */
/* Set the body and html elements to cover the whole screen */
body,
html {
  height: 100%;
  margin: 0;
}
@media (max-width: 768px) {
  .container-123 img {
    width: 90% !important ;
  }
}

/* Set the .containerLyricsPage to cover the whole screen */
.containerLyricsPage {
  display: flex;
  flex-wrap: wrap; /* Wrap the child elements if they don't fit in one row */
  min-height: 100vh; /* Use 100vh to cover the whole viewport */
}

.left-div {
  flex: 1 1 50%; /* Flex-grow, flex-shrink, flex-basis */
  display: flex;
  justify-content: flex-end;
  align-items: flex-start; /* Adjust alignment as needed */
  padding: 20px;
}

.left-div img {
  max-width: 350px;
  height: 350px; /* Maintain aspect ratio for the image */
}

.right-div {
  flex: 1 1 50%; /* Flex-grow, flex-shrink, flex-basis */
  padding: 20px;
  display: flex;
  flex-flow: column;
  color: #ffffff;
}

.right-div p {
  font-size: 18px;
  color: rgb(194, 186, 186);
  font-family: "Lobster", sans-serif;
  font-style: italic;
  letter-spacing: 4px;
  font-weight: 100;
}

.right-div h3 {
  color: rgb(187, 184, 184);
  font-weight: bold;
  line-height: 70px;
  font-family: "Gruppo", sans-serif;
  text-transform: uppercase;
}

/* Set the background of the lyrics page to black */
.lyricsDiv {
  background: rgba(0, 0, 0, 1);
  padding: 0 1rem;
}

/* Optionally, set the containerLyricsPage to cover the whole viewport */
html,
body {
  margin: 0;
  height: 100%;
  scroll-behavior: smooth;
}

@media (max-width: 768px) {
  * {
    background-color: #000;
  }
  .containerLyricsPage {
    flex-direction: column;
  }
  .footerhide {
    display: none;
  }
  .right-div,
  .left-div {
    width: 100%;
    padding: 0px;
    margin: 0 5px;
  }
  .left-div {
    display: flex;
    justify-content: center;
  }
}
/*  Music page cc*/
.musicGrid {
  background-color: rgba(0, 0, 0, 0.991);
}
.musicGrid1,
.musicGrid2,
.musicGrid3,
.musicGrid4,
.musicGrid5,
.musicGrid0,
.musicGrid01 {
  padding: 10px;
  height: max-content;
  display: flex;
  justify-content: center;
  align-items: flex-start; /* Align items at the start of the container */
  flex-wrap: wrap;
  align-self: stretch; /* Stretch the item vertically to match the height of other items */
}

#music1,
#music2,
#music3,
#music4,
#music5,
#music6,
#music7,
#music8,
#music9,
#music10,
#music0,
#music01,
#music_01,
#music_0 {
  width: 500px;
  height: auto;
  padding: 10px;
  font-style: normal;
  color: #fff;
  text-align: center;
}
.musicGrid h4 {
  text-transform: uppercase;
  margin: 15px;
  font-family: "Libre Caslon Text", serif;
}
a {
  text-decoration: none;
  color: #fff;
}
a:hover {
  color: salmon;
}

/* .mainImageContainer
{
  display: flex;
  justify-content: space-around;
  align-items: center;
  object-fit: contain;
  flex-wrap: wrap;
  
}
.musicGrid {
  display: flex;
  justify-content: space-between;
}
.musicImgContainer
{
  width: 550px;
  height: auto;
  padding: 10px;
  font-style: normal;
  color: #fff;
  text-align: center;
}
.musicGrid img{
  width: 350px !important;
  height: 350px !important;
}



.musicGrid h4{
  text-transform: uppercase;


}
.musicGrid1 , .musicGrid2, .musicGrid3, .musicGrid4, .musicGrid5{
 margin: 10px 100px;
  padding: 10px;
  display: flex;
  justify-content: space-between;
  align-items:center;
  max-height: 500px;
}
.musicGrid1 , .musicGrid2, .musicGrid3, .musicGrid4, .musicGrid5 img
{
  height: 100% !important ;
  width: 100% !important ;
}
.music1 , .music2 , .music3, .music4, .music5, .music6, .music7, .music8, .music9, .music10{
  margin:100px;
  padding:10 px;
} */

/* css for parallex effect */
* {
  padding: 0;
  margin: 0;
}
.parent:first-of-type {
  background: #ffffff url("components/images/img6.jpg") no-repeat center center
    fixed;
  background-size: cover;
}
.parent:nth-of-type(2) {
  background: #ffffff url("components/images/img4.jpg") no-repeat center center
    fixed;
  background-size: cover;
}
.parent:nth-of-type(3) {
  background: #ffffff url("components/images/img7.jpg") no-repeat center center
    fixed;
  background-size: cover;
}
.parent:nth-of-type(4) {
  background: #ffffff url("components/images/img2.jpeg") no-repeat center center
    fixed;
  background-size: cover;
}

.parent {
  width: 100%;
  height: 150vh;
  color: #fff;
  text-shadow: 0 0 5px #000;
  font-family: tahoma;
  text-align: center;
  line-height: 180%;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  overflow: hidden;
  /* background-color: rgba(255, 255, 255, 0.1);
  backdrop-filter: blur(100px);
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.9); */
}
.parent p {
  padding: 25px;
  font-size: 22px;
}

/* css for parallex effects ends */

@media (max-width: 768px) {
  .parent:first-of-type {
    background: #ffffff url("components/images/imgPhone/img6.jpg") no-repeat
      center center fixed;
    background-size: cover;
  }
  .parent:nth-of-type(2) {
    background: #ffffff url("components/images/imgPhone/img3.jpg") no-repeat
      center center fixed;
    background-size: cover;
  }
  .parent:nth-of-type(3) {
    background: #ffffff url("components/images//imgPhone/img7.jpg") no-repeat
      center center fixed;
    background-size: cover;
  }
  .parent:nth-of-type(4) {
    background: #ffffff url("components/images//imgPhone/img2.jpeg") no-repeat
      center center fixed;
    background-size: cover;
  }

  .parent {
    width: 100%;
    height: 150vh;
    color: #fff;
    text-shadow: 0 0 5px #000;
    font-family: tahoma;
    text-align: center;
    line-height: 180%;
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
    overflow: hidden;
    /* background-color: rgba(255, 255, 255, 0.1);
    backdrop-filter: blur(100px);
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.9); */
  }
  .parent p {
    padding: 25px;
    font-size: 22px;
  }
  * {
    background-color: rgba(0, 0, 0, 0.2);
  }
}

::-webkit-scrollbar {
  width: 2px;
  border-radius: 50px;
  background: #000;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #444343;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #111111;
}
